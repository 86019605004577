import { find, findAll } from "../utils/dom";

const Page_Conteudos_Lista = () => {
    const page = find("[js-page='conteudos-lista']");
    if (!page) return;

    /** Processo para pegar o shortcode do vídeo do Youtube */
    const videos_youtube = findAll('[js-url-video]');
    videos_youtube?.forEach((video) => {
        const srcVideo = video.getAttribute('js-url-video');
        const urlvideo = srcVideo.split('?')[1].split('&');

        urlvideo.forEach((shortCode) => {
            if (shortCode.includes("v=")) {
                const shortCodeVideo = shortCode.replace('v=', '');
                video.src = `https://www.youtube.com/embed/${shortCodeVideo}?si=n1sWeY3Z-og0Sy82`
            }
        })

    })



    function disabled_content(id_ano) {
        const content = findAll(`[js-ano-drop]`);
        content?.forEach((single_content) => {
            if (!single_content.classList.contains('disabled')) {
                single_content.classList.add('disabled');
            }

            single_content.style.maxHeight = `${single_content.scrollIntoView}px`;

        })

        const botao = find('[js-button-drop].active');
        botao.classList.remove('active')


    }

    function active_content(id_ano) {
        const content = find(`[js-ano-drop='${id_ano}']`);
        content.classList.remove('disabled');

        const botao = find(`[js-button-drop='${id_ano}']`);
        botao.classList.add('active');

    }
    const botoes_ano = findAll('[js-button-drop]');
    botoes_ano?.forEach((botao) => {
        botao.addEventListener("click", (e) => {

            const id_ano = e.target.getAttribute('js-button-drop');
            disabled_content(id_ano);
            active_content(id_ano);


        })
    })

}

export default Page_Conteudos_Lista;