import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import EditorJS from './modules/EditorJS';
import Contact from './modules/Contact';
import Tabela_Performance from './modules/TabelaPerformance';
// import SvgUse from "./utils/SvgUse";

import Section_Footer from './pages/Footer';
import Page_Conteudos from './pages/Conteudos';
import Page_Conteudos_Lista from './pages/Conteudos_Lista';

import Menu from './modules/menu';

import YearNow from './modules/YearNow';

/*
// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config

UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

// Pages
PageHome()
PageLogin()?.init()

Section_Footer()


// utils logs
logs()*/

axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)

Page_Conteudos();
Page_Conteudos_Lista();
YearNow();
Contact();
Tabela_Performance();

EditorJS();

