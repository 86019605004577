import { find } from "../utils/dom";

const YearNow = () => {
    const Container_Year = find('[js-year-now]');
    if (!Container_Year) return;

    const Data = new Date();
    Container_Year.innerText = Data.getFullYear();
}

export default YearNow;