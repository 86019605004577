import axios from 'axios'
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function contatoForm() {
    const showToastify = (message, opts) => {
        Toastify({
            duration: 3000,
            position: "center",
            style: { background: "rgba(193, 255, 129)", fontSize: "18px", color: "rgba(45, 71, 120, 1)" },
            text: message,
            ...opts
        }).showToast();
    }

    const Forms = document.querySelectorAll('.box-contact-form .contact-form');
    if (!Forms) return;

    Forms.forEach((form) => {
        const buttonSubmit = form.querySelector('.btn-default');

        form && buttonSubmit?.addEventListener("click", (e) => {
            e.preventDefault();
            e.target.disabled = true;
            e.target.classList.add("disabled");

            const Nome = form.querySelector('input[name=nome]');
            const Email = form.querySelector('input[name=email]');
            const Telefone = form.querySelector('input[name=telefone]');
            const Mensagem = form.querySelector('textarea[name=mensagem]');

            const form_data = {
                nome: Nome.value,
                telefone: Telefone.value,
                email: Email.value,
                mensagem: Mensagem.value,
            }

            const url = form.getAttribute('action')

            axios.post(url, form_data)
                .then((response) => showMessage(response, form, e.target))
                .catch((response) => showMessage(response, form, e.target));
        })
    })





    /* const btnFormContato = document.querySelectorAll('.btn-contact')
     const formContato = document.querySelector('#enter-in-contact')
     //console.log(formContato);
 
     if (btnFormContato) {
         btnFormContato.forEach((btn) => {
             formContato && btn.addEventListener('click', function (event) {
                 event.preventDefault();
                 disabledBtn()
 
                 const primeiro_nome = document.querySelector('#enter-in-contact input[name=first-name]');
                 const segundo_nome = document.querySelector('#enter-in-contact input[name=last-name]');
                 let telefone = document.querySelector('#enter-in-contact input[name=telephonePTBR]');
                 if (telefone) {
                     telefone = document.querySelector('#enter-in-contact input[name=telephoneEng]');
                 }
                 const email = document.querySelector('#enter-in-contact input[name=email]');
                 const box_text = document.querySelector('#enter-in-contact textarea[name=box-text]');
                 const form_data = {
                     nome: `${primeiro_nome.value} ${segundo_nome.value}`,
                     telefone: telefone.value,
                     email: email.value,
                     mensagem: box_text.value,
                 }
 
                 //console.log(form_data)
 
                 const url = "/fale-conosco/"
                 axios.post(url, form_data)
                     .then((response) => showMessage(response))
                     .catch((response) => showMessage(response));
             });
         })
     }*/

    function showMessage(response, formContato, button_submit) {
        const isSuccess = response.data && response.data.message;
        // const message = response.data ? response.data.message : response.message

        const message = isSuccess ? 'Mensagem enviada com sucesso! Obrigado!' : 'Oops, erro ao enviar! Tente novamente!'

        const backgroundColor = isSuccess ? "rgba(193, 255, 129)" : "rgba(233, 44, 44)";
        const textColor = isSuccess ? "rgba(45, 71, 120, 1)" : "white";

        showToastify(message, {
            style: {
                background: backgroundColor,
                color: textColor,
                fontSize: "18px",
                borderRadius: "8px"
            },
        });
        formContato.reset()
        setTimeout(() => {
            button_submit.disabled = false;
            button_submit.classList.remove('disabled');
        }, 1000)
    }

    function disabledBtn() {
        btnFormContato.forEach((btn) => {
            btn.disabled = true
            btn.classList.add("disabled")
        })
    }

    function enabledBtn() {
        btnFormContato.forEach((btn) => {
            btn.disabled = false
            btn.classList.remove("disabled")
        })
    }
}