import { find, findAll } from "../utils/dom";

const Page_Conteudos = () => {
    const page = find("[js-page='conteudos']");
    if (!page) return;


    function removeFiltro(id_pai) {
        const anterior = find(`#${id_pai} .filtro-active`);
        if (anterior) {
            anterior.classList.remove('filtro-active');
        }
    }

    function addFiltro(No) {
        No.classList.add('filtro-active');
    }

    const filtros = findAll('[js-filtro]');
    if (filtros) {
        filtros.forEach((filtro) => {
            filtro.addEventListener("click", (e) => {
                const id_pai = e.target.closest('select').id;
                removeFiltro(id_pai);
                addFiltro(e.target);

                const Mes_Ano = find('#mes_ano .filtro-active');
                const Value_Mes_Ano = Mes_Ano.value;

                const Semana = find('#semana .filtro-active');
                const Value_Semana = Semana.value;

                const URL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

                const NovaURL = `${URL}?mes_ano=${Value_Mes_Ano}&semana=${Value_Semana}`;
                window.location.replace(NovaURL);
            })
        })
    }

    let atual_url = new URL(window.location.href)
    let semana_url = atual_url.searchParams.get("semana");
    if (semana_url) {

        const container_atual_semana = find('[js-atual-semana]');
        container_atual_semana.innerText = `${semana_url}`;

        const Semana = find('#semana');
        removeFiltro('semana');
        Semana.value = semana_url;
        const Semanas = findAll('#semana option');
        Semanas[parseInt(semana_url) - 1].classList.add('filtro-active');


    }

}

export default Page_Conteudos;